.overflow-hidden {
  overflow: hidden;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.text {
  &--center {
    text-align: center;
  }
}

.color {
  &--accent {
    color: $accent;
  }
}

.pt {
  &-3 {
    padding-top: 80px !important;
  }
}

.pb {
  &-5--xxxl {
    @include xxxlmin {
      padding-bottom: 250px !important;
    }
  }
}
