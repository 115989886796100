.s-cooperation-1 {
  padding-top: 100px;
  padding-bottom: 100px;

  @include lg {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__title {
    margin-bottom: 60px;
    width: 100%;
    opacity: 0;

    @include xxlmin {
      text-align: center;
    }
  }

  &__text {
    opacity: 0;
  }

  &__images {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include xxl {
      order: 1 !important; 
      padding-top: 50px;
    }
  }
}

.s-cooperation-2 {
  background-color: $white;
  padding-top: 100px;
  padding-bottom: 100px;

  @include lg {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__inner {
    align-items: flex-start;
    position: relative;
  }

  &__content-img {
    margin-bottom: 25px;

    @include lg {
      position: absolute;
      bottom: 105%;
      left: 2%;

      width: vw(639);
      height: vw(324);
    }

    @include xs {
      display: none;
    }
  }

  &__title {
    margin-bottom: 30px;
  }

  &__text {
    max-width: 90%;
    margin-left: auto;

    @include xxl {
      max-width: none;
    }
  }

  &__images {
    position: relative;

    @include xxl {
      margin-bottom: 80px;
    }
  }

  &__image {
    @extend %section-image;

    width: 677px;
    height: 987px;

    margin-left: auto;

    @include xxxl {
      width: vw(677);
      height: vw(987);
    }

    @include xs {
      width: 100%;
      height: auto;
      padding-top: percentage(987 / 687);
    }

    &--sm {
      position: absolute;
      bottom: 30px;
      right: 20%;

      width: 562px;
      height: 843px;

      @include xxxl {
        width: vw(562);
        height: vw(843);
      }

      @include xs {
        width: percentage(562 / 687);
        height: auto;
        padding-top: 120%;

        bottom: -30px;
        right: 30px;

      }
    }
  }
}

.s-cooperation-3 {
  position: relative;
  color: $white;

  &__bg {
    @extend %coverdiv;
    background-color: $background;

    img {
      @extend %coverdiv;
      @extend %coverimage;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding-top: 40px;
    padding-bottom: 40px;

    height: calc(#{vw(1024)} - #{$header-height + px});
    position: relative;

    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;

    @include md {
      height: ceil((1024 - $header-height-md) / $line-size-index-md) + px;
    }

    @include xs {
      height: 710px;
    }
  }
}
