.s-travel-1 {
  background-color: $white;
  padding-top: 120px;
  padding-bottom: 130px;

  @include lg {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include xs {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__inner {
    position: relative;
  }

  &__top {
    margin-bottom: 40px;
    min-height: 1300px;
    position: relative;

    @include xxxl {
      padding-bottom: 0;
      min-height: vw(1100);
    }

    @include xl {
      display: flex;
      flex-direction: column;
      min-height: 0;
    }
  }

  &__body {
    > p {
      max-width: $base-container--sm - ($base-container-gap * 2) - 200 + px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image {
    @extend %section-image;

    position: absolute;
    top: 0;
    right: 0;

    width: percentage(820 / $base-container);
    height: 1000px;

    @include xxxl {
      width: vw(700);
      height: vw(853);
    }

    @include container-xl {
      width: vw(600);
      height: vw(731);
    }

    @include xl {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;

      width: vw(820);
      height: vw(1000);
    }

    @include sm {
      width: vw(1000);
      height: vw(1220);
    }

    @include xs {
      width: calc(100% + #{$base-container-gap * 2 + px});
      height: auto;

      margin-left: -$base-container-gap + px;
      margin-right: -$base-container-gap + px;
      margin-bottom: 30px;

      padding-top: percentage(1000 / 820);
    }
  }

  &__title {
    margin-bottom: 80px;
    max-width: 1200px;

    @include xl {
      margin-bottom: 60px;
      max-width: none;
    }

    @include lg {
      margin-bottom: 40px;
      text-align: center;
    }
  }

  &__text {
    max-width: 900px;

    @include lgmin {
      padding-left: $side-title-offset + px;
    }

    @include xxxl {
      max-width: 750px;
    }

    @include xl {
      max-width: none;
    }
  }

  &__compass {
    position: absolute;
    bottom: 0;
    right: percentage(410 / $base-container);
    transform: translate(50%, 0);

    @include xl {
      position: static;
      transform: none;

      margin-left: auto;
      margin-top: 30px;
      margin-right: 10%;
    }
  }
}

.s-travel-2 {
  $this: &;

  padding-top: 85px;
  padding-bottom: 250px;

  @include container-xl {
    padding-bottom: 85px;
  }

  &__image {
    @extend %section-image;
  }

  &__title {
    margin-bottom: 40px;

    max-width: 1240px;

    @include xl {
      max-width: none;
    }
  }

  &__top {
    position: relative;
    min-height: 900px;
    margin-bottom: 50px;

    @include xxxl {
      min-height: vw(650);
    }

    @include xl {
      margin-bottom: 70px;
      min-height: 0;
    }

    @include md {
      margin-bottom: 30px;
    }

    #{$this}__image {
      position: absolute;
      top: -80px;
      right: 0;

      width: percentage(685 / $base-container);
      height: 870px;

      @include xxxl {
        width: vw(500);
        height: vw(641);
      }

      @include xl {
        position: relative;

        width: vw(685);
        height: vw(870);

        margin-left: auto;
        margin-right: auto;
      }

      @include xs {
        width: calc(100% + #{$base-container-gap * 2 + px});
        height: auto;

        margin-left: -$base-container-gap + px;
        margin-right: -$base-container-gap + px;
        margin-bottom: 30px;

        padding-top: percentage(870 / 685);
      }
    }

    #{$this}__text {
      max-width: 1070px;

      @include lgmin {
        padding-left: $side-title-offset + px;
      }

      @include xxxl {
        max-width: 900px;
      }

      @include container-xl {
        max-width: 800px;
      }

      @include xl {
        max-width: none;
      }
    }
  }

  &__bottom {
    position: relative;
    min-height: 500px;

    @include xxxl {
      min-height: vw(500);
    }

    @include container-xl {
      min-height: vw(600);
    }

    @include lg {
      min-height: vw(750);
    }

    @include md {
      min-height: 0;
    }

    #{$this}__title {
      position: relative;
      z-index: 1;
      width: percentage(830 / $base-container);

      margin-left: percentage(650 / $base-container);

      @include md {
        width: 100%;
        margin-left: 0;
        padding-left: 0;
      }
    }

    #{$this}__text {
      width: percentage(830 / $base-container);
      padding-left: 40px;
      margin-left: auto;

      @include md {
        width: 100%;
        margin-left: 0;
        padding-left: 0;
      }
    }

    #{$this}__image {
      position: absolute;
      left: 0;
      top: -100px;

      width: 700px;
      height: 753px;

      @include xxxl {
        width: vw(700);
        height: vw(753);
      }

      @include container-xl {
        top: -50px;
      }

      @include xxl {
        top: 0;
      }

      @include md {
        position: relative;

        width: vw(830);
        height: vw(1000);

        margin-left: auto;
        margin-right: auto;

        margin-bottom: 100px;
      }

      @include xs {
        width: calc(100% + #{$base-container-gap * 2 + px});
        height: auto;
        padding-top: percentage(1000 / 830);

        margin-left: -$base-container-gap + px;
        margin-right: -$base-container-gap + px;
        margin-bottom: 30px;
      }
    }
  }
}

.s-travel-3-1 {
  background-color: $white;
  padding-top: 90px;
  padding-bottom: 70px;

  @include xs {
    padding-top: 0;
  }

  &__images {
    position: relative;

    @include lg {
      margin-bottom: 50px;
    }
  }

  &__image {
    @extend %section-image;

    width: 685px;
    height: 870px;

    margin-left: auto;

    @include xxxl {
      width: vw(685);
      height: vw(870);
    }

    @include container-xl {
      width: vw(580);
      height: vw(737);
    }

    @include lg {
      margin-right: auto;
    }

    @include xs {
      width: calc(100% + #{$base-container-gap * 2 + px});
      height: auto;

      margin-left: -$base-container-gap + px;
      margin-right: -$base-container-gap + px;

      padding-top: percentage(870 / 685);
    }

    &--sm {
      position: absolute;
      z-index: 1;

      top: 10%;
      right: 40%;

      width: 550px;
      height: 700px;

      @include xxxl {
        width: vw(550);
        height: vw(700);
      }

      @include container-xl {
        right: 30%;

        width: vw(480);
        height: vw(610);
      }

      @include xs {
        bottom: -30px;
        top: auto;
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);

        width: 60%;
        height: auto;
        padding-top: percentage(870 / 685) * (60 / 100);
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;

    height: 100%;

    p {
      margin-top: auto;
      margin-left: auto;
      max-width: 500px;

      @include lg {
        margin-left: 0;
      }

      &:last-of-type() {
        margin-bottom: 50px;
      }
    }
  }
}

.s-travel-3 {
  background-color: $white;
  padding-top: 70px;
  padding-bottom: 70px;

  .title {
    margin-top: 0;
  }

  @include xl {
    padding-top: 100px;
  }

  @include md {
    padding-top: 30px;
  }

  .s-banner {
    margin-bottom: 60px;

    &__slog {
      @include sm {
        max-width: none;
      }
    }
  }
}

.s-travel-4 {
  $this: &;

  position: relative;
  background-color: $white;
  padding-top: 145px;
  padding-bottom: 110px;

  min-height: 1300px;

  @include xxxl {
    min-height: vw(1100);
  }

  @include lg {
    padding-top: 70px;
    padding-bottom: 70px;
    min-height: 0;
  }

  @include xs {
    padding-top: 0;
  }

  &__media-wrap {
    background-color: $black;
    position: relative;
    margin-top: 200px;
    padding-bottom: 50px;
    position: relative;

    @include lgmin {
      padding-left: $side-title-offset + px;
    }

    @include lg {
      margin-top: 100px;
    }

    &::before,
    &::after {
      content: '';

      position: absolute;
      top: 0;
      bottom: 0;
      width: 100vw;

      background-color: $black;
    }

    &::after {
      left: 100%;
    }

    &::before {
      right: 100%;
    }
  }

  &__media {
    max-width: 835 - $side-title-offset + px;
    margin-top: -50px;

    @include lg {
      max-width: none;
    }
  }

  &__image {
    @extend %section-image;

    position: absolute;
    top: 0;
    right: 0;

    width: 960px;
    height: 1280px;

    @include xxxl {
      width: vw(800);
      height: vw(1066);
    }

    @include lg {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 100px;
    }

    @include xs {
      margin-bottom: 30px;
      width: 100%;
      height: auto;
      padding-top: percentage(1280 / 960);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: percentage(940 / $base-container);

    @include lg {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 50px;
  }

  &__text {
    max-width: 835px;

    @include lgmin {
      padding-left: $side-title-offset + px;
    }

    @include lg {
      max-width: none;
    }
  }

  &__compass {
    margin-top: 130px;
    margin-left: auto;
    margin-right: 200px;

    @include lg {
      margin-top: 50px;
    }
  }

  &--video {
    overflow: hidden;

    #{$this}__image {
      z-index: 1;
    }

    #{$this}__media-wrap {
      z-index: 0;
    }
  }
}
