.header {
  &__inner {
    display: flex;
    align-items: center;
    height: $header-height + px;
    padding-top: 10px;
    padding-bottom: 10px;

    @include md {
      height: $header-height-md + px;
    }
  }

  &__logo {
    margin-right: 108px;
  }

  &__nav {
    @include md {
      display: none;
    }
  }

  &__burger-wrap {
    display: flex;
    align-items: center;
    margin-left: auto;

    .icon {
      font-size: 34px;
      margin-left: 18px;

      @include md {
        font-size: 21px;
        margin-left: 9px;
      }
    }
  }

  &__burger {
    color: $accent;
  }

  &--light {
    color: $white;
  }
}

.burger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 25px;
  position: relative;

  @include tr(color 0.4s);

  @include md {
    width: 21px;
    height: 13px;
  }

  i {
    display: block;

    &,
    &::after,
    &::before {
      width: 100%;
      height: 5px;
      background-color: currentColor;
      backface-visibility: hidden;

      @include tr(transform 0.3s);

      @include md {
        height: 3px;
      }
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      left: 0;
    }

    &::after {
      top: 0;
    }

    &::before {
      bottom: 0;
    }
  }

  // &[data-state='active'] {
  //   i {
  //     background-color: transparent;

  //     &::before {
  //       transform: rotate(45deg) translate(-7px, -7px);

  //       @include md {
  //         transform: rotate(45deg) translate(-3px, -4px);
  //       }
  //     }

  //     &::after {
  //       transform: rotate(-45deg) translate(-7px, 7px);

  //       @include md {
  //         transform: rotate(-45deg) translate(-3px, 4px);
  //       }
  //     }
  //   }
  // }
}

.logo {
  display: inline-block;
  font-size: 0;

  .icon {
    &--logo {
      font-size: 60px;

      @include md {
        font-size: 40px;
      }
    }

    &--logo-lg {
      font-size: 132px;
    }
  }

  img {
    @include md {
      width: 50px;
      height: 50px;
    }
  }
}
