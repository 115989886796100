.form {
  $this: &;

  &__field {
    &--right {
      text-align: right;
    }
  }

  &--half {
    @extend %row;

    background-color: $white;
    color: $c-text;
    border: 2px solid $black;

    @include sm {
      padding: 30px;
    }

    #{$this}__left,
    #{$this}__right {
      width: 50%;
      padding: 40px 30px;

      @include sm {
        width: 100%;
        padding: 0;
      }
    }

    #{$this}__left {
      border-right: 2px solid $black;

      @include sm {
        border-right: 0;
        margin-bottom: 15px;
      }
    }

    #{$this}__subttl {
      font-size: 28px;
    }

    #{$this}__fields {
      max-width: 500px;
    }

    // #{$this}__field {
    //   @include notlast {
    //     margin-bottom: 5px;
    //   }
    // }
  }
}

.input {
  #{$text-inputs} {
    width: 100%;
    background-color: transparent;
    height: 35px;
    padding: 0.3em;

    border: 0;
    border-bottom: 1px solid $dark;

    @include tr(border-color 0.3s);

    &:focus {
      border-color: $black;
    }
  }

  textarea {
    border: 1px solid $dark;
    min-height: 3.5em;
  }
}
