.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__cursor {
    display: block;

    position: fixed;
    z-index: $z-index-pointer;

    width: 50px;
    height: 50px;

    border: 1px solid currentColor;
    border-radius: 50%;

    color: $accent;
    pointer-events: none;
    cursor: pointer;

    transition: transform .2s;

    .has-menu-open &,
    .has-popup-open & {
      color: inherit;
    }

    &[data-state='active'] {
      background-color: $white;
      border-color: $white;
      mix-blend-mode: difference;
    }
  }

  &__bg {
    @extend %overlay;

    z-index: 0;

    &[data-state='top'] {
      top: 150px;
    }

    &[data-state='bottom'] {
      bottom: 150px;
    }
  }

  &__preloader {
    @extend %overlay;

    z-index: $z-index-preloader;
    background-color: $background;
  }

  &__wrap {
    position: relative;
    z-index: 1;

    .no-touch & {
      @extend %overlay;
    }
  }

  &__line {
    position: relative;
    z-index: $z-index-line;
    opacity: 0;
  }

  &__header {
    position: absolute;
    z-index: $z-index-controls;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;

    @include md {
      position: fixed;
      // background-color: $white;
      // color: $c-text;
    }

    .is-offline & {
      top: $offline-panel-height + px;
    }

    &[data-state='fixed'] {
      position: fixed;
      background-color: $white;
      color: $c-text;
    }

    &--shrinked {
      transform: translate(0, -100%);
    }

    &--loaded {
      @include tr(transform 0.5s $easeInSine, background-color 0.5s);
    }
  }

  &__main {
    position: relative;
    flex-grow: 1;
    overflow: hidden;

    .is-offline & {
      padding-top: $offline-panel-height + px;
    }
  }

  &__navigation {
    position: fixed;
    z-index: $z-index-controls;
    right: 80px;
    top: 50%;
    transform: translate(0, -50%);

    @include tr(color 0.5s);

    opacity: 0;

    @include xxxl {
      right: 40px;
    }

    @include xl {
      display: none;
    }

    .has-menu-open &,
    .has-popup-open & {
      display: none;
    }

    &--static {
      opacity: 1;
    }
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    top: -60%;
    bottom: -60%;
    z-index: $z-index-controls + 1;
    font-size: 0;
    transform: translate(0, 200%);

    svg {
      width: 100%;
      height: percentage(1 / 3);
    }

    @include before {
      position: absolute;
      top: percentage(1 / 3) - 1%;
      left: 0;
      width: 100%;
      height: percentage(1 / 3) + 2%;
      background-color: $background;
    }
  }

  &__offline-panel {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: $z-index-controls;
  }

  &__request-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-controls;
  }

  &__menu {
    @extend %overlay;

    z-index: $z-index-menu;

    bottom: auto;
    height: 100%;

    .no-touch & {
      height: 100vh;
    }
  }
}
