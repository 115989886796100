.s-error {
  background-color: $white;
  padding-top: $header-height + px;
  text-align: center;

  @include md {
    padding-top: $header-height-md + px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: 60px;
    padding-bottom: 60px;

    min-height: calc(100vh - #{$header-height + px});

    @include md {
      min-height: calc(100vh - #{$header-height-md + px});
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    min-height: 660px;
    margin-bottom: 50px;

    width: 100%;

    @include xxxl {
      min-height: vw(660);
    }
  }

  &__nav {
    position: relative;
    z-index: 2;
  }

  &__title {
    @extend .title;
    font-size: 675px;

    @include xxxl {
      font-size: vw(675);
    }
  }

  &__image-block {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);

    margin-top: 100px;

    @include xxxl {
      margin-top: vw(100);
    }
  }
}

.error-image {
  width: 625px;
  height: 581px;

  @include xxxl {
    width: vw(625);
    height: vw(581);
  }

  &__text {
    @extend %flex-center;

    position: absolute;
    top: -80px;
    right: 80%;

    width: 535px;
    height: 238px;

    @include xl {
      width: 450px;
      height: 200px;
    }

    @include md {
      width: 350px;
      height: 156px;
    }

    @include xs {
      width: 250px;
      height: 111px;
    }

    span {
      @extend .title;

      position: absolute;
      z-index: 1;
      top: 30%;
      left: 15%;
      transform: rotate(3deg);

      font-size: 27px;
      white-space: nowrap;

      @include xl {
        font-size: 21px;
      }

      @include md {
        font-size: 18px;
        left: 12%;
      }

      @include xs {
        font-size: 0.85em;
        left: 10%;
      }
    }

    img {
      width: 100%;
    }
  }
}
