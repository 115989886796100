.s-sitemap {
  padding-top: $header-height + 100 + px;
  padding-bottom: 100px;
  min-height: 80vh;
  position: relative;

  @include md {
    padding-top: $header-height-md + 70 + px;
    padding-bottom: 70px;
  }

  &__compass {
    position: absolute;
    bottom: 10%;
    left: 10%;

    @include lg {
      left: 5%;
    }

    @include md {
      position: relative;
    }
  }

  &__title {
    margin-bottom: 100px;
    text-align: center;

    @include lg {
      margin-bottom: 70px;
    }
  }

  &__nav {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    @include xxxl {
      max-width: vw(700);
    }

    @include lg {
      max-width: 600px;
    }

    @include sm {
      max-width: 500px;
    }

    @include xs {
      max-width: 400px;
    }

    @include xxs {
      max-width: 320px;
    }

    @include xxs {
      max-width: 300px;
    }
  }
}

.sitemap {
  @extend %row;

  margin-left: -15px;
  margin-right: -15px;

  &__item {
    width: percentage(1 / 2);

    padding-left: 15px;
    padding-right: 15px;
  }
}
