.page-navigation {
  $this: &;
  $item-width: 14;
  $item-height: 55;

  &[data-theme='dark'] {
    color: $white;
  }

  &::before {
    content: attr(data-label);
    position: absolute;
    top: 50%;
    left: -210px;
    width: 320px;
    white-space: nowrap;
    transform: rotate(-90deg) translate(4%, 0);
    font-weight: 700;
    text-align: center;
  }

  &.fade-in-before {
    &::before {
      animation: fade-in 0.5s;
    }
  }

  &.fade-out-before {
    &::before {
      animation: fade-out 0.5s;
    }
  }

  &__list {
    position: relative;
    counter-reset: list-navigation;
  }

  &__item {
    width: $item-width + px;
    height: $item-height + px;
    border: 1px solid currentColor;
    position: relative;

    &::before {
      content: counter(list-navigation, decimal-leading-zero) '';
      counter-increment: list-navigation;
      font-size: 10px;
      position: absolute;
      bottom: 0;
      right: calc(100% + 0.2em);
      transform: rotate(-90deg) translate(-50%, 0);
    }

    &:not(:last-child) {
      border-bottom: 0;
    }
  }

  &__btn {
    --progress: 0%;

    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    @include before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: $item-width + px;
      background-color: rgba($accent, 0.4);
      transform: translate(0, calc(var(--progress) - 100%));
    }

    &[href] {
      @include before {
        transition: transform 0.5s $easeInOutSine;
      }

      @include hover {
        --progress: 100%;
      }
    }
  }

  &.layout__navigation--static {
    #{$this}__list {
      --progress: -100%;

      overflow: hidden;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba($accent, 0.4);
        transform: translate(0, var(--progress));
        transition: transform 0.5s $easeInOutSine;
      }
    }

    #{$this}__btn {
      &::before {
        display: none;
      }
    }
  }
}
