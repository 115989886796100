.s-order {
  background-color: $black-light;
  color: $white;
  padding-top: 167px;
  padding-bottom: 166px;

  @include xl {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include lg {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__inner {
    max-width: 868px;
    margin-left: auto;
    margin-right: auto;
  }
}
