.order-list {
  @extend %row;

  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -60px;

  @include lg {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -30px;
  }

  &__item {
    width: 50%;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 60px;

    @include lg {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 30px;
    }

    @include xs {
      width: 100%;
    }
  }
}

.order-item {
  $this: &;

  display: flex;
  flex-direction: column;
  text-align: center;

  position: relative;

  height: 100%;
  padding-top: 90px;

  @include lg {
    padding-top: 70px;
  }

  @include hover {
    #{$this}__img {
      img {
        transform: scale(1.1);
      }

      &--contain {
        img {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
  }

  &__title {
    display: block;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    width: 100%;
  }

  &__img {
    display: block;
    background-color: $black-lighten;
    position: relative;
    padding-top: percentage(486 / 404);
    flex-grow: 1;
    overflow: hidden;

    img {
      @extend %coverdiv;
      @extend %coverimage;

      @include tr(transform 0.4s $easeInOutSine);
    }

    &--contain {
      img {
        @extend %vcenter;
        object-fit: contain;
        
        max-width: 65%;
        max-height: 65%;
      }
    }
  }
}
