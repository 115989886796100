.s-brand {
  padding-top: vw(162);
  padding-bottom: vw(231);
  position: relative;
  z-index: $z-index-line + 1;

  @include xxxlmin {
    padding-top: vw(250);
  }

  @include md {
    padding-top: 200px;
    padding-bottom: 80px;
  }

  @include xs {
    padding-top: 120px;
  }

  &__inner {
    position: relative;
    height: vw(1292);

    @include md {
      height: ceil(1292 / $line-size-index-md) + px;
    }

    @include xs {
      height: 900px;
    }
  }

  &__content {
    max-width: 805px;
    margin-left: 2.14%;
    position: relative;
    z-index: 1;
    padding-top: vw(322);
    padding-bottom: vw(92);

    @include container-xl {
      max-width: 450px;
    }

    @include xl {
      padding-top: vw(200);
    }

    @include lg {
      max-width: 600px;
    }

    @include md {
      padding-top: 0;
      margin-left: 0;
      max-width: none;
    }

    .section-block__title {
      @include xl {
        margin-left: 70px;
      }

      @include xs {
        margin-left: auto;
      }
    }
  }

  &__content-text {
    display: flex;
    flex-direction: column;
    max-width: 610px;

    @include xl {
      max-width: 500px;
    }

    @include lg {
      max-width: 400px;
    }

    @include md {
      max-width: none;
    }

    > p {
      @include xs {
        max-width: percentage(270 / ($base-mobile - $base-container-gap * 2));
        margin-left: auto;
      }

      @include xxs {
        max-width: 270px;
      }
    }

    .btn {
      margin-left: auto;
    }
  }

  &__images {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: percentage(1094 / $base-desktop);

    @include xxl {
      width: 53%;
    }

    @include md {
      position: relative;
      width: 100%;
      padding-top: 55%;
    }

    @include sm {
      padding-top: 63%;
    }

    @include xxs {
      padding-top: 53%;
    }

    @include r(415) {
      padding-top: 63%;
    }
  }

  &__image {
    font-size: 0;

    img {
      @extend %coverimage;
    }

    &:nth-child(1) {
      @extend %coverdiv;
      background-color: $background;
    }

    &:nth-child(2) {
      @extend %vcenter;

      width: percentage(932 / 1094);

      @include md {
        width: 45%;
      }
    }
  }
}

.s-brand-1 {
  background-color: $white;
  padding-top: 80px;
  padding-bottom: 80px;

  @include sm {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__inner {
    position: relative;
  }

  &__img {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    width: percentage(1090 / 1920);
    padding-top: percentage(830 / 1090) * (1090 / 1920);

    background-color: $background;

    @include xxxlmin {
      width: 50%;
      padding-top: percentage(830 / 1090) * (50 / 100);
    }

    @include xxl {
      width: 40%;
      padding-top: percentage(830 / 1090) * (40 / 100);
    }

    @include lg {
      position: relative;
      margin-bottom: 60px;
      
      width: 60%;
      padding-top: percentage(830 / 1090) * (60 / 100);
    }

    @include xs {
      width: 100%;
      padding-top: percentage(830 / 1090);
      margin-bottom: 30px;
    }

    img {
      @extend %coverdiv;
      @extend %coverimage;
    }
  }

  &__content-wrap {
    position: relative;
    z-index: 1;
  }

  &__content {
    width: 100%;
    max-width: percentage(760 / $base-container);

    margin-left: auto;
    margin-top: -50px;
    padding-bottom: 150px;

    padding-left: 50px;

    @include xxxlmin {
      max-width: 50%;
    }

    @include xxl {
      max-width: 60%;
      padding-bottom: 50px;
    }

    @include lg {
      max-width: none;
      padding-left: 0;
      padding-bottom: 0;
      margin-top: 0;
    }
  }

  &__title {
    margin-left: -150px;

    @include lg {
      margin-left: 0;
    }
  }
}
