.s-blog {
  background-color: $light;
  padding-top: $header-height + px;
  overflow: hidden;

  &__author {
    margin-bottom: 65px;

    @include lg {
      margin-bottom: 40px;
    }
  }

  &__title-block {
    margin-bottom: 140px;

    max-width: 800px;

    @include lg {
      margin-bottom: 80px;
    }
  }

  &__title {
    margin-bottom: 15px;
  }
}
