.s-article {
  overflow: hidden;
  
  &__social {
    position: fixed;
    z-index: $z-index-controls;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);

    .has-menu-open & {
      display: none;
    }

    @include xs {
      bottom: 0;
      left: 0;
      top: auto;
      transform: none;
    }
  }

  &__hero {
    padding-top: $header-height + 60 + px;
    padding-bottom: 85px;
    background-color: $light;

    @include md {
      padding-top: $header-height-md + 30 + px;
    }
  }

  &__hero-content {
    @extend %row;
  }

  &__images {
    position: relative;
    width: percentage(1050 / $base-container);

    padding-top: 1em;

    @include sm {
      width: calc(100% + #{$base-container-gap * 2 + px});

      margin-left: -$base-container-gap + px;
      margin-right: -$base-container-gap + px;
    }
  }

  &__image {
    @extend %section-image;

    width: 831px;
    height: 687px;

    margin-left: auto;

    @include xxxl {
      width: vw(831);
      height: vw(687);
    }

    @include container-xl {
      width: vw(750);
      height: vw(620);
    }

    @include sm {
      width: vw(831);
      height: vw(687);
    }

    @include xs {
      width: 100%;
      height: auto;
      padding-top: 90%;
    }

    &--sm {
      position: absolute;
      top: 90px;
      right: 40%;

      width: 600px;
      height: 755px;

      @include xxxl {
        width: vw(600);
        height: vw(755);
      }

      @include container-xl {
        width: vw(500);
        height: vw(629);
      }

      @include sm {
        width: vw(600);
        height: vw(755);
      }

      @include xs {
        display: none;
        // height: auto;
        // width: 50%;
        // padding-top: 70%;
        // left: 40px;
        // right: auto;
        // top: auto;
        // bottom: -20px;
      }
    }
  }

  &__text {
    width: percentage(($base-container - 1050) / $base-container);
    padding-right: 58px;
    padding-left: 108 + 60 + px; // Header logo width and margin.

    @include container-xl {
      padding-left: 0;
    }

    @include md {
      padding-right: 25px;
    }

    @include sm {
      width: 100%;
      padding-right: 0;
      margin-bottom: 30px;
    }
  }

  &__title {
    width: 100%;
    max-width: 50%;
    margin-left: 108 + 58 + px; // Header logo width and margin.

    margin-bottom: 15px;

    @include container-xl {
      margin-left: 0;
    }

    @include md {
      max-width: 70%;
    }

    @include sm {
      max-width: none;
    }
  }

  &__date {
    display: inline-block;
    margin-bottom: 30px;
  }

  &__main {
    background-color: $white;
    padding-top: 30px;
    padding-bottom: 30px;

    &:nth-child(2) {
      padding-top: 140px;

      @include container-xl {
        padding-top: 50px;
      }

      @include xs {
        padding-top: 30px;
      }
    }
  }
}
