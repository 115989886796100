.menu {
  overflow: hidden;
  color: $c-text;
  @extend %hidden;

  @include tr(opacity 0.5s, visibility 0.5s);

  &::before {
    content: '';

    position: absolute;
    top: -150vw;
    right: -150vw;
    z-index: 0;

    width: 300vw;
    height: 300vw;

    border-radius: 50%;
    background-color: $accent;

    opacity: 0;
    transform: translate(0%, 0%) scale(0);
    transition: transform 0.75s $easeOutQuad, opacity 0.3s;

    @media (orientation: portrait) {
      right: -150vh;
      top: -150vh;
      width: 300vh;
      height: 300vh;
    }
  }

  &__top {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;

    opacity: 0;

    .is-offline & {
      top: $offline-panel-height + px;
    }
  }

  &__top-inner {
    @extend .header__inner;

    justify-content: space-between;
  }

  &__inner {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: auto;
    position: relative;
    z-index: 1;

    > .container {
      height: 100%;
      @include vertical;
    }
  }

  &__nav {
    display: inline-block;
    vertical-align: middle;

    position: relative;
    z-index: 1;

    .nav__item {
      opacity: 0;
    }
  }

  &[data-state='open'] {
    @extend %visible;

    &::before {
      transform: translate(1%, 0%) scale(1);
      opacity: 1;
    }
  }
}
