.blog-list {
  @extend %row;

  margin-left: -14px;
  margin-right: -14px;

  @include lg {
    margin-left: -10px;
    margin-right: -10px;
  }

  &__item {
    width: percentage(1 / 4);
    padding-left: 14px;
    padding-right: 14px;

    margin-bottom: 35px;

    @include lg {
      padding-left: 10px;
      padding-right: 10px;
    }

    @include md {
      width: percentage(1 / 2);
    }

    @include sm {
      width: 100%;
    }

    &--full {
      width: 100%;
      position: relative;
      background-color: $white;

      padding-top: 35px;
      padding-bottom: 35px;

      @include sm {
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;
      }

      &::before,
      &::after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        width: 50vw;
        background-color: $white;

        @include sm {
          display: none;
        }
      }

      &::before {
        right: 100%;
      }

      &::after {
        left: 100%;
      }
    }
  }
}

.blog-card {
  $this: &;

  display: flex;
  flex-direction: column;
  height: 100%;

  &__img {
    display: block;
    position: relative;
    background-color: $background;
    padding-top: percentage(310 / 415);
    overflow: hidden;

    @include hover {
      img {
        transform: scale(1.1);
      }
    }

    img {
      @extend %coverdiv;
      @extend %coverimage;

      @include tr(transform 0.4s $easeInOutSine);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 30px 0;
    flex-grow: 1;
  }

  &__date {
    display: inline-block;
    margin-bottom: 20px;
  }

  &__title {
    @include line-clamp(2, 2.7em);
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include sm {
      margin-bottom: 15px;
    }
  }

  &__text {
    display: none;
    width: 100%;
    margin-bottom: 25px;

    @include sm {
      display: block;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    width: 100%;

    @include xxxl {
      flex-direction: column;
      align-items: flex-start;

      .btn {
        margin-bottom: 15px;
      }
    }
  }

  &--full {
    @include smmin {
      flex-direction: row;
    }

    #{$this}__img {
      @include smmin {
        width: percentage(860 / $base-container);
        padding-top: 27.5%;
      }
    }

    #{$this}__content {
      display: flex;
      flex-direction: column;

      @include smmin {
        width: percentage(($base-container - 860) / $base-container);
        padding: 55px 45px;
      }

      @include lg {
        padding: 30px;
      }

      @include sm {
        padding: 30px 0;
      }
    }

    #{$this}__text {
      display: block;
      flex-grow: 1;
    }

    #{$this}__title {
      @extend .title;
      @extend .title--simple;
      @extend .title--h3-sm;

      @include sm {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        text-transform: uppercase;
      }
    }
  }
}
