.s-events {
  background-color: $white;
  padding-top: 40px;
  padding-bottom: 100px;
  position: relative;

  &__compass {
    position: absolute;

    &:nth-child(1) {
      top: 70px;
      left: 50%;
    }

    &:nth-child(2) {
      bottom: 0px;
      right: 10%;
    }
  }
}
