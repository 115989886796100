.btn {
  $this: &;

  @include f-title;

  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  border: 3px solid $accent;
  min-width: 281px;
  padding: 0.8rem 2rem;

  &__title {
    position: relative;
    z-index: 1;
  }

  &:not(#{$this}--no-hover) {
    @extend %fancy-hover;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &--visible {
    &::before {
      animation: hover 1s $easeInSine;
    }
  }

  &--sm {
    font-size: 1em;
    min-width: 180px;
    padding: 0.3rem 0.8rem;
  }

  &--xs {
    min-width: 120px;
    padding: 0.2rem 0.8rem;
    font-size: 1em;
  }

  &--lg {
    // extend .title--h2-sm
    font-size: 80px;

    @include xl {
      font-size: 70px;
    }

    @include lg {
      font-size: 60px;
    }

    @include sm {
      font-size: 50px;
    }

    @include xs {
      font-size: 40px;
    }

    @include xxs {
      font-size: 28px;
    }
  }

  &--filled {
    $transition-duration: 400;

    &::before {
      transition-delay: $transition-duration + ms;
      transform: translate(0, 0) !important;
    }

    &::after {
      transform: translate(0, 0) !important;
    }

    &:not(#{$this}--no-hover) {
      @include hover {
        &::before {
          transform: translate(101%, 0) !important;
        }

        &::after {
          transition-duration: 0s;
          transition-delay: 0s;
          transform: translate(-101%, 0) !important;
        }
      }
    }
  }
}
