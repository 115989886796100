.nav {
  $this: &;

  text-transform: uppercase;
  font-weight: 700;

  &--main {
    font-size: 0.9em;

    #{$this}__list {
      display: flex;
      align-items: center;
    }

    #{$this}__item {
      @include notlast {
        margin-right: 50px;
      }
    }

    #{$this}__link {
      @extend %fancy-hover;

      display: inline-block;
      padding-bottom: 0.2em;

      &::before,
      &::after {
        height: 5px;
      }

      &--current {
        @include before {
          transform: translate(0, 0);
        }
      }
    }
  }

  &--center {
    #{$this}__list {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: -15px;
      margin-right: -15px;
    }

    #{$this}__item {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &--vert {
    text-transform: none;

    #{$this}__list {
      @extend %row;
    }

    #{$this}__item {
      width: 100%;

      @include notlast {
        margin-bottom: 20px;
      }

      @include md {
        width: percentage(1 / 2);
      }
    }

    #{$this}__link {
      @extend %fancy-hover;

      display: inline-block;
      padding-bottom: 0.2em;

      &::before,
      &::after {
        height: 5px;
      }

      &--active {
        @include before {
          transform: translate(0, 0);
        }
      }
    }
  }

  &--xl {
    font-size: 80px;
    font-weight: 400;

    @include xxl {
      font-size: 60px;
    }

    @include lg {
      font-size: 50px;
    }

    @include sm {
      font-size: 40px;
    }

    @include xs {
      font-size: 30px;
    }

    #{$this}__item {
      &:nth-child(odd) {
        #{$this}__img {
          left: auto;
          right: 110%;
        }
      }

      &:nth-last-child(1),
      &:nth-last-child(2) {
        #{$this}__img {
          top: auto;
          bottom: 0;
        }
      }
    }

    #{$this}__link {
      position: relative;

      @include tr(color 0.4s);

      @include hover {
        @extend #{$this}__link--current;

        #{$this}__img {
          visibility: visible;
          opacity: 0.5;
          transform: skew(0) translate(0, 0) rotate(-11deg);
        }
      }

      &--current {
        color: rgba($c-text, 0.4);
      }
    }

    #{$this}__link-title {
      position: relative;
      z-index: 1;
    }

    #{$this}__img {
      display: block;

      position: absolute;
      max-width: none;

      width: 369px;
      height: 472px;

      z-index: 0;
      top: 0;
      left: 110%;

      filter: grayscale(100%);

      transition: opacity 0.75s, visibility 0.75s, transform 0.75s $easeInSine;

      @include hidden;
      transform: skew(-3deg, -8deg) translate(0px, 30px);

      @include xxxl {
        width: vw(369);
        height: vw(472);
      }

      @include lg {
        display: none;
      }

      html:not(.no-touch) & {
        display: none;
      }
    }
  }

  &--sitemap {
    #{$this}__sublist {
      font-size: 0.8em;
      margin-left: 1em;
    }

    #{$this}__item {
      @include notlast {
        margin-bottom: 15px;
      }

      #{$this}__link {
        margin-bottom: 15px;
      }
    }

    #{$this}__subitem {
      @include notlast {
        margin-bottom: 15px;
      }
    }

    #{$this}__link,
    #{$this}__sublink {
      @extend %fancy-hover;

      display: inline-block;
      padding-bottom: 0.2em;

      &::before,
      &::after {
        height: 5px;
      }

      &--active {
        @include before {
          transform: translate(0, 0);
        }
      }
    }
  }
}
