.section {
  &__title {
    position: relative;
    padding-bottom: 0.3em;

    @include xs {
      text-align: center;
      max-width: 355px;
      margin-left: auto;
      margin-right: auto;
    }

    @include before {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      background-color: $accent;
      width: 0;
      max-width: 100%;

      @include tr(width 0.4s);
    }

    &[data-state='visible'] {
      @include before {
        width: 266px;

        @include xs {
          width: 170px;
        }
      }
    }
  }

  &__btn {
    text-align: center;
  }
}

.section-block {
  $this: &;

  @extend .text;

  display: flex;
  flex-direction: column;

  .has-svg-curve & {
    > p {
      @include xs {
        max-width: percentage(270 / ($base-mobile - $base-container-gap * 2));
        margin-left: auto;
      }

      @include xxs {
        max-width: 270px;
      }
    }
  }

  .btn {
    margin-left: auto;
    // margin-top: vw(45);

    @include xs {
      margin-right: auto;
    }
  }

  &__title {
    margin-bottom: vw(70);

    @include xs {
      margin-bottom: 30px;
    }
  }

  &__subttl {
    margin-top: vw(-65);
    margin-bottom: 30px;

    @include xs {
      margin-top: -25px;
    }
  }

  &--line {
    position: relative;
    padding-top: 1em;

    &::before {
      content: '';

      position: absolute;
      left: 0;
      top: 0;

      width: 0;
      height: 2px;
      background-color: $accent;

      @include tr(width 0.4s);
    }
  }

  &[data-state='visible'] {
    @include before {
      width: 100px;
    }
  }
}
