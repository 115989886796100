.article {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend .title;
  }

  h1 {
    margin-bottom: 30px;
  }

  h2, h3 {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  p, ul, ol, img {
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  img {
    margin-top: 60px;
    margin-bottom: 60px;

    &.full-width {
      width: 100%;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul, ol {
    margin-left: 1.1em;
  }
}
