.hero {
  $this: &;

  position: relative;
  color: $white;
  background-color: $background-light;
  padding-top: $header-height + px;

  @include md {
    padding-top: $header-height-md + px;
  }

  &__bg {
    @extend %coverdiv;
    z-index: 0;
    background-color: $background;

    img {
      @extend %coverdiv;
      @extend %coverimage;
    }
  }

  &__inner {
    height: calc(#{vw(1024)} - #{$header-height + px});
    position: relative;

    padding-top: vw(145);

    @include lg {
      padding-top: vw(50);
    }

    @include md {
      height: ceil((1024 - $header-height-md) / $line-size-index-md) + px;
    }

    @include xs {
      height: 710px;
      padding-top: 0;
    }
  }

  &--home {
    overflow: hidden;

    @include after {
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100px;
      pointer-events: none;
      background-image: linear-gradient(
        0deg,
        rgba($background-light, 0.8),
        rgba($background-light, 0)
      );
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    #{$this}__bg {
      @extend %coverdiv;

      background-color: $background;

      img,
      span {
        position: absolute !important;
        object-fit: contain;
        left: -5% !important;
        width: 110%;
        max-width: 110%;
        top: auto !important;

        @media (min-height: 63vw) {
          object-fit: cover;
        }

        @include lg {
          object-fit: cover;
          width: 120%;
          max-width: 120%;
        }

        &:nth-child(1) {
          top: -9% !important;
          z-index: 1;

          @include lg {
            left: -8% !important;
          }
        }

        &:nth-child(2) {
          top: -6% !important;
          z-index: 0;

          @include lg {
            left: -8% !important;
          }

          @include xs {
            top: -10% !important;
            left: -10% !important;
          }
        }

        &:nth-child(3) {
          z-index: 2;
          top: -11% !important;
        }

        &:nth-child(4) {
          z-index: 3;
          bottom: 3% !important;

          @include lg {
            display: none;
          }
        }

        &:nth-child(5) {
          z-index: 4;

          @media (min-height: 63vw) {
            object-position: 75% 50%;
          }

          &[data-processed='true'] {
            bottom: -7% !important;
          }
        }
      }
    }

    #{$this}__media {
      position: absolute;
      z-index: $z-index-line + 1;
      bottom: vw(70);
      right: vw(69);
      opacity: 0;

      @include xxxl {
        right: 0;
      }

      @include xs {
        bottom: 20px;
        left: 50%;

        margin-left: -262 / 2 + px;
      }
    }

    #{$this}__title {
      line-height: 0.9;

      @include smmin {
        padding-left: percentage(105 / $base-container);
        max-width: percentage(8 / 12);
      }

      @include xs {
        font-size: 70px;
      }

      @include xxxs {
        font-size: 50px;
      }

      span {
        display: inline-block;
        opacity: 0;

        &:nth-child(2) {
          margin-left: 1.1em;

          @include xs {
            margin-left: 0.7em;
          }
        }
      }
    }
  }

  &--about {
    background-color: $black;

    @include xs {
      background-color: #120f12;
    }

    #{$this}__title {
      margin-bottom: 15px;
      opacity: 0;
    }

    #{$this}__subttl {
      opacity: 0;
    }

    #{$this}__img {
      opacity: 0;
    }

    #{$this}__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      @include xs {
        position: static;
      }
    }

    #{$this}__img {
      width: 100%;
      max-width: 862px;
      padding-top: 1024px;

      position: absolute;
      z-index: 0;
      left: 0;
      top: 50px;

      @include xxxlmin {
        top: auto;
        bottom: -50px;
      }

      @include xxxl {
        max-width: vw(700);
        padding-top: vw(832);
      }

      @include md {
        max-width: vw(862);
        padding-top: vw(1024);
      }

      @include sm {
        max-width: vw(1024);
        padding-top: vw(1218);
      }

      @include xs {
        width: 100%;
        max-width: none;
        padding-top: 0;
        bottom: 0;
        top: 60px;
      }

      img {
        @extend %coverdiv;
        @extend %coverimage;
      }
    }

    #{$this}__content {
      position: relative;
      z-index: 1;
      margin-left: auto;
      max-width: 960px;
    }
  }

  &--brand {
    #{$this}__img {
      opacity: 0;
    }

    #{$this}__inner {
      @extend %flex-center;

      z-index: 1;
      padding: 0;

      height: auto !important;

      min-height: calc(#{vw(1024)} - #{$header-height + px});

      @include md {
        min-height: calc(100vh - #{$header-height-md + px});
      }
    }
  }

  &--cooperation {
    #{$this}__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      padding-top: 30px;
      padding-bottom: 30px;
    }

    #{$this}__content {
      margin-right: 10%;

      @include xs {
        margin-right: 0;
      }
    }

    #{$this}__title {
      margin-bottom: 15px;
      opacity: 0;
    }

    #{$this}__subttl {
      max-width: 750px;
      margin-left: 5em;
      opacity: 0;

      @include xs {
        margin-left: 0;
      }
    }
  }

  &--events {
    background-color: $white;
    color: inherit;

    #{$this}__title,
    #{$this}__subttl,
    #{$this}__img {
      opacity: 0;
    }

    #{$this}__inner {
      @extend %row;

      align-items: center;

      padding-top: 70px;

      max-width: 1515px;
      margin-left: auto;
      margin-right: auto;

      @include xxxlmin {
        height: 1024 - $header-height + px;
      }

      @include md {
        padding-top: 0;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    #{$this}__img {
      width: percentage((1515 - 600) / 1515);
      padding-top: 40%;
      height: 100%;
      position: relative;
      background-color: $background;

      opacity: 0;

      @include md {
        width: 100%;
        height: auto;
        flex-grow: 1;
      }

      img {
        @extend %coverdiv;
        @extend %coverimage;
      }
    }

    #{$this}__hint-img {
      position: absolute;
      bottom: 0;
      right: 0;

      transform: translate(150%, 0);
    }

    #{$this}__content {
      width: percentage(600 / 1515);
      padding-right: 50px;

      @include md {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    #{$this}__title {
      margin-bottom: 15px;
    }
  }

  &--travels {
    --bottom-offset: 285px;
    $bottom-offset: var(--bottom-offset, 285px);

    margin-bottom: $bottom-offset;

    @include xxxl {
      --bottom-offset: 200px;
    }

    @include lg {
      --bottom-offset: 100px;
    }

    @include md {
      padding-top: 0;
      background-color: $white;
      color: $c-text;
    }

    @include xs {
      --bottom-offset: 50px;
      margin-bottom: 0;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 100%;
      left: 0;
      right: 0;

      height: $bottom-offset;
      background-color: $white;
    }

    #{$this}__title,
    #{$this}__subttl {
      @include mdmin {
        text-shadow: 1px 1px 2px rgba($black, 0.9);
      }
    }

    #{$this}__inner {
      padding-top: vw(300);
      padding-bottom: 100px;
      height: auto !important;
      min-height: calc(#{vw(1024)} - #{$header-height + px});

      @include md {
        min-height: 0;

        position: static;

        padding-top: 50px;
        padding-bottom: 50px;
      }
    }

    #{$this}__bg {
      @include md {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: percentage(1017 / 1919);
      }
    }

    #{$this}__img {
      position: absolute;
      z-index: 1;
      top: -70px;
      left: 0;

      width: 47%;
      height: 1165px;

      background-color: $background;

      @include xxxl {
        height: auto;
        padding-top: percentage(1174 / 851) / (100 / 47);
      }

      @include md {
        top: 0;
        width: 40%;
        padding-top: percentage(1174 / 851) / (100 / 40);
      }

      @include xs {
        top: 0;
        width: 45%;
        padding-top: percentage(1174 / 851) / (100 / 45);
      }

      img {
        @extend %coverdiv;
        @extend %coverimage;
      }
    }

    #{$this}__content {
      position: relative;
      z-index: 2;

      margin-left: auto;
      width: 53%;
      padding-left: 20px;

      @include md {
        width: 100%;
        padding-left: 0;
      }
    }

    #{$this}__title {
      margin-bottom: 30px;
    }

    #{$this}__subttl {
      padding-left: 85px;

      @include lg {
        padding-left: 0px;
      }
    }
  }

  &--travel-page {
    #{$this}__inner {
      @include lgmin {
        padding-left: $side-title-offset + px;
      }
    }

    #{$this}__title,
    #{$this}__subttl {
      text-shadow: 1px 1px 2px rgba($black, 0.9);
    }

    #{$this}__title {
      margin-bottom: 50px;

      opacity: 0;
    }

    #{$this}__subttl {
      max-width: 600px;

      opacity: 0;
    }

    #{$this}__bg {
      @include sm {
        img {
          object-position: 75% 50%;
        }
      }
    }
  }
}
