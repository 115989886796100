$base-fz: 17;
$base-fz-lg: 15;
$base-desktop: 1920;
$base-mobile: 375;
$base-container: 1760;
$base-container-xxl: 1560;
$base-container-xl: 1280;
$base-container--sm: 1200;
$base-container-gap: 10;

$text-inputs: "[type='color']", "[type='date']", "[type='datetime']", "[type='datetime-local']",
  "[type='email']", "[type='month']", "[type='number']", "[type='password']", "[type='search']",
  "[type='tel']", "[type='text']", "[type='time']", "[type='url']", "[type='week']",
  'input:not([type])', 'textarea';
$check-inputs: "[type='checkbox']", "[type='radio']";

$c-text: #000;
$background: var(--background, #5a5b36);
$background-light: #e5e5e5;
$background-lighten: #c4c4c4;
$accent: #ec600b;
$accent-secondary: #535353;
$black: #000;
$black-light: #121313;
$black-lighten: #1B1B1B;
$white: #fff;
$danger: red;
$dark: #646464;
$light: #f5f7f5;

$header-height:        72;
$header-height-md:     60;
$offline-panel-height: 35;

$z-index-line:      10;
$z-index-controls:  15;
$z-index-menu:      20;
$z-index-modal:     25;
$z-index-pointer:   30;
$z-index-preloader: 35;


$line-size-index-md: 1.39;

$scrollbar-width: 8;

$side-title-offset: 90;

:root {
  --background: #5a5b36;
}
