.s-news {
  $this: &;

  background-color: $background;
  color: $white;
  padding-top: vw(106);
  padding-bottom: vw(106);

  html:not(.has-svg-curve) & {
    @include lg {
      margin-bottom: 20vw;
    }

    @include xs {
      margin-bottom: 25vw;
    }
  }

  .has-svg-curve & {
    @include xs {
      padding-top: 80px;
    }
  }

  &__title {
    position: relative;
    z-index: $z-index-line + 1;
  }

  &__link {
    font-size: 44px;
    white-space: nowrap;

    @include hover {
      text-decoration: underline;
    }

    @include sm {
      font-size: 30px;
    }
  }

  @include xs {
    padding-top: 65px;
    padding-bottom: 70px;
  }

  &--side {
    $bottom-offset: 200;

    margin-bottom: vw($bottom-offset);
    padding-bottom: 0;
    height: vw(873);

    @include xxl {
      padding-top: vw(60);
    }

    @include md {
      height: ceil(873 / $line-size-index-md) + px;
      margin-bottom: 105px;
    }

    @include xs {
      height: 465px;
      margin-bottom: 130px;
      padding-top: 40px;
    }

    #{$this}__title {
      padding-left: vw(500);

      @include md {
        padding-left: 42%;
      }

      @include xs {
        padding-left: 0;
        text-align: center;
      }
    }

    #{$this}__slider {
      position: relative;
      bottom: vw(-$bottom-offset);
      margin-top: vw(-$bottom-offset);
      z-index: $z-index-line + 2;

      @include md {
        bottom: ceil(-$bottom-offset / $line-size-index-md) + px;
        margin-top: ceil(-$bottom-offset / $line-size-index-md) + px;
      }
    }
  }

  &--center {
    padding-top: 46px;
    padding-bottom: 90px;
    overflow: hidden;

    #{$this}__title {
      text-align: center;
      margin-bottom: 47px;
    }

    #{$this}__slider {
      margin-bottom: 58px;
      z-index: $z-index-line + 1;

      @include sm {
        padding-left: $base-container-gap + px;
        padding-right: $base-container-gap + px;
      }

      .slider__swiper-container-wrap {
        @include smmin {
          margin-left: percentage(-488 / $base-desktop);
          margin-right: percentage(-488 / $base-desktop);
        }
      }
    }
  }

  &--link {
    > .container {
      @include lgmin {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    #{$this}__title {
      padding-left: 0;
    }
  }
}
