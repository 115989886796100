.popup {
  $this: &;
  $overlay-x-center: var(--pointer-coordinates-x, 50%);
  $overlay-y-center: var(--pointer-coordinates-y, 50%);

  @extend %overlay;
  @extend %hidden;

  z-index: $z-index-modal;
  overflow: hidden;

  transition: opacity 0.75s, visibility 0.75s;

  &::after {
    content: '';

    position: absolute;
    z-index: 0;
    left: $overlay-x-center;
    top: $overlay-y-center;

    width: 250vw;
    height: 250vw;
    border-radius: 100%;

    background-color: $accent;

    transition: transform 0.5s $easeOutQuad, opacity 0.3s;
    transform: translate(-50%, -50%) scale(0);

    @media (max-width: 100vh) {
      width: 250vh;
      height: 250vh;
    }
  }

  &__body {
    width: 100%;
    height: 100%;

    overflow: auto;
    padding: 60px 15px 15px;

    position: relative;
    z-index: 1;

    @include vertical;
  }

  &__inner {
    display: inline-block;
    vertical-align: middle;

    width: 100%;
    font-size: 1rem;

    opacity: 0;
  }

  &__close {
    position: absolute;
    z-index: 2;
    top: 25px;
    right: 25px;
  }

  &__media {
    width: 70vw;
    height: (352 / 627 * 70) + vw;

    margin-left: auto;
    margin-right: auto;

    object-fit: contain;

    display: none;

    @media (max-width: 100vh) {
      width: calc(100vw - 30px);
      height: (352 / 627 * 100) + vw;
    }
  }

  &--thanks {
    // backdrop-filter: blur(10px);
    &::after {
      // backdrop-filter: blur(10px);
      background-color: transparent;
    }

    @include vertical;

    padding: 15px;

    #{$this}__body {
      display: inline-block;
      vertical-align: middle;

      width: 100%;
      height: auto;

      padding: 0;

      max-width: 900px;
    }

    #{$this}__inner {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 60px 40px;

      min-height: 550px;

      background-color: $accent;

      position: relative;
    }
  }

  &--active {
    @extend %visible;

    &::after {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }

    #{$this}__media {
      display: block;
    }
  }
}
