.events-all-list {
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;

  &__item {
    position: relative;

    &::before {
      content: attr(data-title);

      display: block;

      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

      @extend .title;
      font-size: 155px;

      transform-origin: 0% 0%;
      transform: rotate(-90deg) translate(-100%, -100%);

      opacity: 0.2;

      @include xxl {
        font-size: 120px;
      }

      @include xl {
        position: static;
        transform: none;
        margin-bottom: 30px;

        font-size: 100px;
      }
    }
  }
}

.events-list {
  $this: &;

  @extend %clr;

  &__item {
    margin-bottom: 100px;
    float: left;

    @include md {
      margin-bottom: 50px;
    }

    @include sm {
      width: 100% !important;
      float: none !important;

      margin-bottom: 30px;
    }

    &--1 {
      width: percentage(840 / $base-container);
    }

    &--2 {
      width: percentage(620 / $base-container);
      float: right;
      margin-top: 200px;

      @include md {
        width: 40%;
      }

      @include sm {
        margin-top: 0;
      }
    }

    &--3 {
      width: percentage(1000 / $base-container);
    }
  }
}

.event-card {
  $this: &;

  display: flex;

  @include sm {
    display: block;
  }

  @include hover {
    #{$this}__img {
      img {
        transform: scale(1.1);
      }
    }
  }

  &__img,
  &__content,
  &__title,
  &__text {
    display: block;
  }

  &__img {
    position: relative;
    background-color: $background;
    overflow: hidden;

    padding-top: 40%;
    width: 55%;

    @include sm {
      padding-top: 70% !important;
      width: 100%;
    }

    img {
      @extend %coverdiv;
      @extend %coverimage;

      @include tr(transform 0.4s $easeInOutSine);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 25px;
    width: 45%;

    @include sm {
      padding: 25px 0;
      width: 100%;
    }
  }

  &__title {
    @include line-clamp(3, 3.6em);

    margin-bottom: 25px;
  }

  &__text {
    @include line-clamp(7, 9.7em);
    margin-bottom: 25px;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__date {
    @extend .title;
    font-size: 29px;

    @include lg {
      font-size: 24px;
    }
  }

  &--1,
  &--2,
  &--3 {
    flex-direction: column;

    #{$this}__img,
    #{$this}__content {
      width: 100%;
    }

    #{$this}__content {
      display: block;
    }
  }

  &--1 {
    #{$this}__img {
      padding-top: percentage(440 / 785);
    }
  }

  &--2 {
    #{$this}__img {
      padding-top: percentage(565 / 1000);
    }
  }

  &--3 {
    #{$this}__img {
      padding-top: percentage(565 / 1000);
    }
  }
}
