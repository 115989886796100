.media {
  width: vw(400);

  @include lg {
    font-size: 18px;
    text-align: center;

    @include md {
      width: 262px;
    }
  }

  &__caption,
  &__button {
    @include notlast {
      margin-bottom: 10px;
    }
  }

  &__caption {
    @include f-title;

    text-transform: uppercase;
  }

  &__button {
    display: block;
    position: relative;
    width: 100%;
    padding-top: vw(300);
    background-color: $background;
    border: 5px solid rgba(98, 98, 98, 0.6);
    box-shadow: 0px 4px 64px rgba(52, 51, 23, 0.35);

    @include md {
      padding-top: 186px;
    }

    @include before {
      @extend %coverdiv;

      z-index: 1;
      background-color: rgba($black, 0.2);
    }
  }

  &__bg {
    @extend %coverdiv;

    @extend %coverimage;

    z-index: 0;
  }

  &__play {
    @extend %vcenter;

    z-index: 2;
    width: vw(85);
    height: vw(85);

    @include md {
      width: 62px;
      height: 62px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
