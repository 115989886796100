.scrollbar-track {
  background-color: $background-lighten;

  @include hidden;
  @include tr(opacity 0.4s, visibility 0.4s);

  &.show {
    @include visible;
  }
}

.scrollbar-thumb {
  background-color: $accent-secondary;
}

.scrollbar-track-y {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: $scrollbar-width + px;
}

.scrollbar-thumb-y {
  width: 100%;
}
