%overlay {
  @include overlay;
}

%coverdiv {
  @include coverdiv;
}

%cover {
  @include cover;
}

%clr {
  @include clr;
}

%hidden {
  @include hidden;
}

%visible {
  @include visible;
}

%vcenter {
  @include vcenter;
}

%text-ovdrflow {
  @include text-overflow;
}

%hard-hidden {
  display: none;
}

%row {
  display: flex;
  flex-wrap: wrap;
}

%coverimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%fancy-hover {
  $transition-duration: 400;

  position: relative;
  overflow: hidden;

  @include hover {
    &::before {
      @include tr(transform $transition-duration + ms $easeInSine);

      transform: translate(0, 0);
    }

    &::after {
      transition-delay: $transition-duration + ms;
      transform: translate(0, 0);
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $accent;
  }

  &::before {
    transform: translate(-102%, 0);
  }

  &::after {
    @include tr(transform $transition-duration + ms $easeInSine);

    transform: translate(102%, 0);
  }
}

%section-image {
  position: relative;
  background-color: $background;
  box-shadow: 0px 4px 64px rgba(52, 51, 23, 0.35);

  img,
  iframe,
  .map {
    @extend %coverdiv;
    @extend %coverimage;
  }
}
