.card {
  $this: &;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: $white;
  position: relative;

  @include hover-focus {
    #{$this}__bg {
      @include before {
        opacity: 0;
      }
    }
  }

  &__bg {
    @extend %coverdiv;

    z-index: 0;
    background-color: $background-light;

    img {
      @extend %coverdiv;

      @extend %coverimage;
    }

    @include before {
      @extend %coverdiv;

      background-color: rgba($black, 0.4);
      z-index: 1;

      @include tr(opacity 0.5s $easeInOutSine);
    }
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__date,
  &__text,
  &__title {
    display: block;
  }

  &__title,
  &__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__title {
    -webkit-line-clamp: 1;
    max-height: 1.5em;
  }

  &__text {
    -webkit-line-clamp: 4;
    max-height: 5.5em;
  }

  &--vert {
    padding: vw(40) vw(50);
    height: vw(630);

    @include md {
      height: 500px;
    }

    @include xs {
      height: 353px;
    }

    #{$this}__date {
      @include f-title;

      text-transform: uppercase;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 10px;

      @include lg {
        font-size: 30px;
      }
    }

    #{$this}__title {
      @include f-title;

      font-weight: 700;
      text-transform: uppercase;
      -webkit-line-clamp: 5;
      max-height: 7em;
    }
  }

  &--hor {
    padding: vw(338) vw(55) vw(60);

    @include sm {
      padding: percentage(43 / 200) 19px 15px;
    }

    @include xs {
      padding: percentage(35 / 200) 19px 15px;
    }

    #{$this}__date {
      font-weight: 700;
      margin-bottom: 5px;
    }

    #{$this}__title {
      margin-bottom: 5px;
      font-size: 30px;
      font-weight: 700;

      @include lg {
        font-size: 18px;
      }

      @include xs {
        margin-bottom: 5px;
      }
    }

    #{$this}__text {
      @include lgmin {
        max-width: 80%;
      }
    }
  }
}
