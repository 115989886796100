.footer {
  $cols-gap: var(--cols-gap, 70px);

  border-top: 1px solid rgba($white, 0.4);
  background-color: $black-lighten;
  color: $white;

  --cols-gap: 70px;

  @include container-xl {
    --cols-gap: 50px;
  }

  @include md {
    text-align: center;
  }

  &__inner {
    @extend %row;

    @include mdmin {
      padding-top: 51px;
      padding-bottom: 51px;
    }
  }

  &__left,
  &__center,
  &__right {
    display: block;

    @include md {
      padding-top: 51px;
      padding-bottom: 51px;
    }
  }

  &__left {
    width: 30%;

    display: flex;
    flex-direction: column;

    @include md {
      width: 100%;
    }
  }

  &__center {
    width: 40%;

    border-right: 1px solid rgba($white, 0.4);
    border-left: 1px solid rgba($white, 0.4);

    padding-left: $cols-gap;
    padding-right: $cols-gap;

    @include md {
      width: calc(100% + #{$base-container-gap * 2 + px});

      margin-left: -$base-container-gap + px;
      margin-right: -$base-container-gap + px;

      padding-left: $base-container-gap + px;
      padding-right: $base-container-gap + px;

      border-right: 0;
      border-left: 0;
      border-top: 1px solid rgba($white, 0.4);
      border-bottom: 1px solid rgba($white, 0.4);
    }
  }

  &__right {
    width: 30%;

    position: relative;

    padding-left: $cols-gap;

    @include md {
      width: 100%;
      padding-left: 0;
      padding-bottom: (51 * 2) + px;
    }
  }

  &__auth {
    position: absolute;
    bottom: 0;
    right: 0;

    @include md {
      bottom: 51px;
      right: $base-container-gap + px;
    }

    a {
      display: block;
      font-size: 0;
    }
  }

  &__title {
    margin-bottom: 50px;

    a {
      transition: color 0.4s;

      @include hover {
        color: $accent;
      }
    }
  }

  &__copy {
    margin-top: auto;

    @include md {
      position: absolute;
      left: $base-container-gap + px;
      bottom: 51px;
    }
  }
}

.instagram-photos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  &__link {
    display: block;
    position: relative;
    background-color: $background;
    padding-top: 100%;

    img {
      @extend %coverdiv;
      @extend %coverimage;
    }
  }
}
