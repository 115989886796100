.prev,
.next {
  @extend %flex-center;

  width: 30px;
  height: 30px;

  @include tr(color 0.4s);

  @include hover {
    color: $accent;
  }

  .icon {
    font-size: 25px;

    @include xs {
      font-size: 16px;
    }
  }

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.prev {
  transform: scaleX(-1);
}

.slider {
  &__wrap {
    position: relative;
    overflow: hidden;

    * {
      box-sizing: border-box;
    }

    .swiper-slide {
      -webkit-backface-visibility: hidden;
    }

    .swiper-wrapper {
      -webkit-transform-style: preserve-3d;
    }

    .swiper-pagination {
      position: relative;
      width: 100%;
      height: 10px;
      background-color: $accent-secondary;

      @include xs {
        height: 7px;
      }
    }

    .swiper-pagination-progressbar-fill {
      background-color: $white;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    color: $white;
    margin-right: 50px;

    @include xs {
      margin-right: 22px;
    }
  }

  &__swiper-container-wrap {
    overflow: hidden;
    margin-bottom: 40px;
  }

  &__elements {
    display: flex;
    align-items: center;
  }

  &__prev {
    margin-right: 27px;

    @include xs {
      margin-right: 15px;
    }
  }

  &--items-side {
    &__wrap {
      .swiper-container {
        padding-top: 70px;
        padding-bottom: 70px;

        @include xxxl {
          padding-top: 50px;
          padding-bottom: 30px;
        }
      }

      .swiper-pagination {
        background-color: $background-lighten;
      }

      .swiper-pagination-progressbar-fill {
        background-color: $black;
      }

      .swiper-slide {
        @include tr(transform 0.4s);

        box-shadow: 0px 4px 20px rgba(52, 51, 23, 0.35);
        height: auto;
        font-size: 1rem;
      }

      .slider {
        &__controls {
          color: $black;
        }

        &__swiper-container-wrap {
          margin-right: percentage(-183 / $base-desktop);
          margin-bottom: 0;

          @include sm {
            margin-right: percentage(-230 / $base-mobile);
            padding-left: $base-container-gap + px;
          }
        }
      }
    }
  }

  &--items {
    &__wrap {
      .slider {
        .swiper-slide {
          height: auto;

          @include tr(transform 0.4s);

          .card {
            min-height: 100%;
          }

          &:not(.swiper-slide-active) {
            @include smmin {
              transform: scale(0.95);
            }

            @include lgmin {
              transform: scale(0.9);
            }
          }
        }
      }
    }
  }
}
