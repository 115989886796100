html,
body {
  height: 100%;
}

html {
  @include reg;

  font-size: $base-fz + px;
  line-height: 1.2;
  color: $c-text;

  @include lg {
    font-size: $base-fz-lg + px;
  }
}

body {
  background-color: $background;
  min-width: 375px;

  .has-noise-bg & {
    &::before {
      content: '';

      @extend %overlay;
      z-index: 0;

      background-color: $background-light;
      background-image: url('../img/noise.png');
      background-repeat: repeat;
      background-size: 49px 49px;
    }
  }
}

* {
  outline-color: $accent;
}

.offline-panel {
  background-color: $background;
  color: $danger;
  text-align: center;
  font-size: 0.7em;

  &__inner {
    @extend %flex-center;

    height: $offline-panel-height + px;
  }
}

.request-panel {
  background-color: $background;
  color: $white;
  position: relative;

  // &__close {
  //   position: absolute !important;
  //   top: 50%;
  //   right: $base-container-gap + px;
  //   transform: translate(0, -50%);
  // }

  &__inner {
    @extend %flex-center;
    flex-direction: column;
    flex-wrap: wrap;

    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__text {
    margin-bottom: 10px;
  }

  &__button {
    margin-left: 5px;
    margin-right: 5px;
  }

  &__buttons {
    display: flex;
    align-items: center;

    margin-left: -5px;
    margin-left: -5px;
  }
}

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit;
}

.lazy {
  $this: &;

  &--loaded:not(#{$this}--no-fade-in) {
    animation: fade-in 1s;
  }
}

.main {
  &__title,
  &__btn {
    background-color: $white;
    padding-top: 75px;
    padding-bottom: 75px;
    text-align: center;

    @include xxl {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    @include sm {
      padding-top: 30px;
      padding-bottom: 50px;
    }
  }
}

.container {
  width: 100%;
  max-width: $base-container + $base-container-gap * 2 + px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $base-container-gap + px;
  padding-right: $base-container-gap + px;

  @include xxxl {
    max-width: $base-container-xxl + px;
  }

  @include container-xl {
    max-width: $base-container-xl + px;
  }

  &--sm {
    max-width: $base-container--sm + px;
  }
}

.compass {
  font-size: 0;

  img {
    width: vw(164);
    height: vw(164);

    @include md {
      width: 128px;
      height: 128px;
    }
  }
}

.js-parallax {
  @include tr(transform 0.5s cubic-bezier(0.51, 0.66, 0.13, 0.71));
}

.author {
  position: relative;

  &__meta {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;

    @include lg {
      max-width: none;
      margin-bottom: 30px;
    }
  }

  &__social {
    position: absolute;
    bottom: 0;
    left: 0;

    @include lg {
      position: static;
    }
  }

  &__img {
    width: 80px;
    height: 80px;

    background-color: $background;

    position: relative;
    overflow: hidden;

    border-radius: 50%;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

    img {
      @extend %coverdiv;
      @extend %coverimage;
    }
  }

  &__title {
    margin-bottom: 5px;
  }

  &__subttl {
    color: $background-lighten;
    margin-bottom: 5px;
  }
}

.youtube-player {
  $this: &;
  
  position: relative;
  padding-top: 65%;
  background-color: $black;
  cursor: pointer;

  > img#{$this}__img {
    object-fit: cover;
  }

  &__img {
    @extend %coverdiv;

    display: block;
    width: 100%;
    height: 100%;

    margin: 0 !important;
    border: 0;
  }

  &__load {
    @extend %vcenter;
    z-index: 0;

    margin: 0 !important;
  }

  &__caption {
    @extend %vcenter;
    z-index: 1;
    color: $accent;

    .icon {
      font-size: 90px;

      @include sm {
        font-size: 60px;
      }
    }
  }
}

.side-controls {
  width: 45px;

  @include xs {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: $white;
  }

  &__item {
    margin-bottom: 2px;

    @include xs {
      margin-bottom: 0;
      width: percentage(1 / 3);

      @include notlast {
        padding-right: 2px;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    background-color: $accent;

    padding: 0 10px;

    height: 45px;
    width: 65px;

    @include lg {
      height: 50px;
      padding: 0 10px;
    }

    @include xs {
      height: 100%;
      width: 100%;
      justify-content: center;
    }

    .icon {
      font-size: 24px;

      &--facebook {
        margin-left: 0.3em;
      }
    }

    @include tr(transform $easeInOutSine 0.5s);

    @include hover {
      transform: translate(calc(-100% + 45px), 0);
    }
  }
}

.close {
  @include icon-close(38px, 3px, currentColor);
  @include tr(color 0.4s);
  @include hover-focus {
    color: rgba($c-text, 0.4);
  }
}

.date {
  border-bottom: 2px solid $accent;
  text-transform: uppercase;
}
