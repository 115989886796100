.s-book {
  $this: &;

  position: relative;
  z-index: $z-index-line + 1;
  padding-top: vw(153);
  padding-bottom: vw(100);
  height: vw(2037);

  @include xl {
    padding-top: vw(80);
  }

  @include md {
    height: ceil(2037 / $line-size-index-md) + px;
    padding-top: ceil(153 / $line-size-index-md) + px;
    padding-bottom: ceil(100 / $line-size-index-md) + px;
  }

  @include xs {
    height: 1715px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__image {
    position: relative;
    background-color: $background;
    box-shadow: 0px 4px 64px rgba(52, 51, 23, 0.35);

    img {
      @extend %coverdiv;

      @extend %coverimage;
    }
  }

  &__inner {
    @extend %row;
  }

  &__left {
    display: flex;
    flex-direction: column;
    width: percentage(895 / $base-container);

    #{$this}__image,
    #{$this}__media-wrap {
      margin-left: auto;
      max-width: percentage(684 / 895);
      width: vw(684);
    }

    #{$this}__image {
      padding-top: vw(1030);
      margin-bottom: vw(105);

      @include xxxl {
        padding-top: vw(900);
      }

      @include md {
        margin-bottom: ceil(105 / $line-size-index-md) + px;
      }

      @include sm {
        margin-bottom: 0;
        width: vw(234, 600);
        padding-top: vw(354, 600);
        z-index: 1;
        left: 0;
        bottom: 550px;
      }

      

      @include xs {
        width: vw(234, 414);
        padding-top: vw(354, 414);
      }

      @include xxs {
        width: vw(200, 414);
        padding-top: vw(303, 414);
      }

      @include r(385) {
        width: vw(234, $base-mobile);
        padding-top: vw(354, $base-mobile);
      }
    }
  }

  &__right {
    width: percentage(($base-container - 895) / $base-container);
    padding-left: 42px;

    @include sm {
      width: 100%;
      padding-left: 0;
    }

    #{$this}__image {
      padding-top: vw(1040);
      width: vw(823);
      max-width: 100%;
      margin-left: auto;

      @include xxxl {
        padding-top: vw(900);
      }

      @include xl {
        padding-top: vw(750);
        width: vw(595);
      }

      @include md {
        width: 80%;
        padding-top: 110%;
      }

      @include sm {
        margin-bottom: 0;
        width: vw(226, 600);
        padding-top: vw(383, 600);
        z-index: 0;
        right: 0;
        bottom: 400px;
      }

      @include xs {
        width: vw(226, 414);
        padding-top: vw(383, 414);
      }

      @include xxs {
        width: vw(190, 414);
        padding-top: vw(322, 414);
      }

      @include r(385) {
        width: vw(226, $base-mobile);
        padding-top: vw(383, $base-mobile);
      }
    }
  }

  &__text {
    margin-bottom: vw(111);

    @include container-xl {
      padding-right: 60px;
    }

    @include xl {
      margin-bottom: vw(50);
      padding-right: 0;
    }

    @include md {
      margin-bottom: 160px;
    }

    @include sm {
      margin-bottom: 0;
      padding-left: 70px;
    }

    @include xs {
      padding-left: 0;
    }

    > .title {
      @include xl {
        margin-bottom: 30px;
      }
    }

    > .btn {
      margin-top: 0;
    }
  }

  &__compass {
    margin-left: auto;
    margin-right: 48px;
    margin-bottom: vw(52);

    @include xxxlmin {
      margin-left: 0;
    }

    @include md {
      margin-bottom: 280px;
    }

    @include sm {
      left: 20%;
      bottom: 120px;
      z-index: 10;
    }

    @include xs {
      left: 5%;
    }
  }

  &__media {
    @include sm {
      bottom: 40px;
      left: 70px;
    }
  }

  &__image,
  &__media,
  &__compass {
    @include sm {
      position: absolute;
    }
  }
}
