.line {
  position: absolute;
  z-index: $z-index-line;
  top: vw(565);
  left: 0;
  width: vw(695);
  height: vw(7485);
  perspective: 1000px;
  transform-style: preserve-3d;
  pointer-events: none;

  @include container-xl {
    perspective: 5000px;
  }

  @include md {
    width: ceil(695 / $line-size-index-md) + px;
    height: ceil(7485 / $line-size-index-md) + px;
    top: ceil(760 / $line-size-index-md) + px;
  }

  @include xs {
    width: 265px;
    height: 5944px;
    top: 300px;
    left: -25px;
  }

  &__svg {
    width: 100%;
    height: 100%;
  }

  &__dot {
    @include tr(opacity 0.25s);
  }

  &__btn {
    --lds-ripple-size: #{vw(140)};

    @extend %flex-center;

    position: absolute;
    z-index: $z-index-line;
    top: vw(565 - 71);
    left: vw(202);
    width: vw(71);
    height: vw(71);
    color: $white;

    @include md {
      --lds-ripple-size: 100px;
      width: ceil(71 / $line-size-index-md) + px;
      height: ceil(71 / $line-size-index-md) + px;
      top: ceil((765 - 71) / $line-size-index-md) + px;
      left: ceil(202 / $line-size-index-md) + px;
    }

    @include xs {
      top: 255px;
      left: 164px;
    }

    i {
      @include icon-arrow(0.7, 0.7, 0.1, currentColor, -135);

      margin-left: -0.5em;
    }

    &[data-state='active'] {
      @include after {
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) 1;
      }
    }

    @include before {
      @extend %coverdiv;

      display: block;
      border-radius: 100%;
      border: 2px solid rgba($white, 0.5);
      border-top-color: $white;
      animation: loading 3s linear infinite;
    }

    @include after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 5px solid rgba($white, 0.5);
      border-radius: 50%;
      opacity: 0;
    }
  }

  &__caption {
    --left: 10%;
    position: absolute;
    left: var(--left, 10%);

    // font-weight: 600;
    font-weight: 700;
    font-size: 0.8em;

    text-transform: uppercase;
    color: $accent;
    white-space: nowrap;
    text-align: center;

    width: 100px;

    transform: rotateX(18deg);

    @include hidden;
    @include tr(transform 0.4s, opacity 0.4s, visibility 0.4s);

    @include container-xl {
      --left: 8%;
      transform: rotateY(80deg) rotateZ(270deg);
      transform-origin: 30% 100%;
    }

    @include xs {
      --left: 20%;
    }

    &[data-state='active'] {
      transform: rotateX(0deg);

      @include visible;

      @include container-xl {
        transform: rotateY(0deg) rotateZ(270deg);
      }
    }

    &:nth-child(1) {
      top: 10.15%;

      @include xs {
        top: 15.55%;
      }
    }

    &:nth-child(2) {
      top: 26.5%;
      left: 45%;

      @include xxl {
        left: 44%;
      }

      @include xs {
        top: 26.5%;
        left: 48%;
      }
    }

    &:nth-child(3) {
      top: 38.15%;

      @include container-xl {
        top: 38.3%;
      }

      @include xs {
        top: 43.35%;
        left: 23%;
      }
    }

    &:nth-child(4) {
      top: 78.3%;

      @include xs {
        top: 83%;
      }
    }

    &:nth-child(5) {
      bottom: 1.95%;

      @include xs {
        display: none;
      }
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(720deg);
    opacity: 1;
  }

  50% {
    border-top-color: rgba($white, 0.5);
    transform: rotate(720deg);
  }

  100% {
    border-top-color: rgba($white, 0.5);
    transform: rotate(720deg);
  }
}

@keyframes lds-ripple {
  0% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  100% {
    width: var(--lds-ripple-size, #{vw(140)});
    height: var(--lds-ripple-size, #{vw(140)});
    opacity: 0;
  }
}
