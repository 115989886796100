.social {
  $this: &;

  @extend %flex-center;

  margin-left: -15px;
  margin-right: -15px;

  @include lg {
    margin-left: -5px;
    margin-right: -5px;
  }

  &__item {
    margin-left: 15px;
    margin-right: 15px;

    @include lg {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  &__link {
    @include tr(color 0.4s);

    @include hover {
      color: $accent;
    }
  }

  &--circle {
    #{$this}__link {
      @extend %flex-center;

      width: 40px;
      height: 40px;

      border: 1px solid currentColor;
      border-radius: 50%;

      .icon {
        font-size: 0.8em;
      }
    }
  }

  &--vert {
    display: block;

    margin-left: 0;
    margin-right: 0;

    #{$this}__item {
      margin-left: 0;
      margin-right: 0;

      @include notlast {
        margin-bottom: 20px;
      }
    }
  }

  &--text {
    font-size: 1.3em;
    font-weight: 700;
    text-transform: capitalize;
  }
}
