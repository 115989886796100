.title {
  @include f-title;

  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 400;

  &--h1 {
    font-size: 176px;

    @include xxxl {
      font-size: 150px;
    }

    @include xxl {
      font-size: 140px;
    }

    @include xl {
      font-size: 120px;
    }

    @include lg {
      font-size: 80px;
    }

    @include xs {
      font-size: 50px;
    }

    @include xxs {
      font-size: 30px;
    }
  }

  &--h2 {
    font-size: 100px;

    @include container-xl {
      font-size: 80px;
    }

    @include lg {
      font-size: 70px;
    }

    @include sm {
      font-size: 50px;
    }

    @include xs {
      font-size: 40px;
    }

    @include xxs {
      font-size: 30px;
    }
  }

  &--h2-sm {
    font-size: 80px;

    @include container-xl {
      font-size: 60px;
    }

    @include xl {
      font-size: 50px;
    }

    @include xs {
      font-size: 40px;
    }

    @include xxs {
      font-size: 28px;
    }
  }

  &--h2-sm-l {
    font-size: 90px;

    @include container-xl {
      font-size: 75px;
    }

    @include xl {
      font-size: 65px;
    }

    @include lg {
      font-size: 55px;
    }

    @include sm {
      font-size: 45px;
    }

    @include xs {
      font-size: 35px;
    }

    @include xxs {
      font-size: 28px;
    }
  }

  &--h2-md {
    font-size: 130px;

    @include container-xl {
      font-size: 110px;
    }

    @include xl {
      font-size: 90px;
    }

    @include lg {
      font-size: 80px;
    }

    @include sm {
      font-size: 70px;
    }

    @include xs {
      font-size: 50px;
    }

    @include xxs {
      font-size: 30px;
    }
  }

  &--h3 {
    font-size: 60px;

    @include container-xl {
      font-size: 50px;
    }

    @include lg {
      font-size: 40px;
    }

    @include xxs {
      font-size: 28px;
    }
  }

  &--h3-lg {
    font-size: 70px;

    @include container-xl {
      font-size: 55px;
    }

    @include lg {
      font-size: 45px;
    }

    @include xs {
      font-size: 35px;
    }

    @include xxs {
      font-size: 28px;
    }
  }

  &--h3-sm {
    font-size: 46px;

    @include container-xl {
      font-size: 38px;
    }

    @include lg {
      font-size: 32px;
    }

    @include xxs {
      font-size: 28px;
    }
  }

  &--h5 {
    font-size: 19px;
  }

  &--line {
    position: relative;
    display: inline-block;
    padding-left: 1.4em;

    @include lg {
      padding-left: 0;
      padding-bottom: 0.2em;
      display: block;
      text-align: center;
    }

    &::before {
      content: '';

      position: absolute;
      top: 0.55em;
      left: 0;

      height: 2px;
      width: 1.15em;
      background-color: $accent;

      @include lg {
        left: 50%;
        top: auto;
        bottom: 0;
        transform: translate(-50%, 0);
      }
    }
  }

  &--simple {
    text-transform: none;
  }
}

.subttl {
  @include f-title;

  font-weight: 400;
  text-transform: uppercase;

  &--h2 {
    font-size: 35px;

    @include container-xl {
      font-size: 28px;
    }

    @include lg {
      font-size: 24px;
    }

    @include sm {
      font-size: 21px;
    }

    @include xs {
      font-size: 18px;
    }

    @include xxs {
      font-size: 16px;
    }
  }

  &--h3 {
    font-size: 22px;

    @include xxs {
      font-size: 16px;
    }
  }

  &--lower {
    text-transform: none;
  }
}

.text {
  p,
  ul,
  ol {
    @include notlast {
      margin-bottom: 20px;
    }
  }

  ul,
  ol {
    margin-left: 1em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    &:not(.btn) {
      text-decoration: underline;
    }
  }
}
