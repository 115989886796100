.s-half {
  $this: &;

  &__inner {
    @extend %row;
  }

  &__image {
    width: percentage(($base-container - 760 - 70) / $base-container);
    max-width: percentage(($base-container - 760 - 70) / $base-container);
    order: 1;

    @include container-xl {
      width: 49%;
    }

    @include lg {
      width: 100%;
      max-width: none;
      order: 0;
    }
  }

  &__content {
    width: percentage((760 + 70) / $base-container);
    padding-right: 70px;

    position: relative;
    z-index: 1;

    @include lg {
      width: 100%;
      padding-right: 0;
    }
  }

  &--reverse {
    #{$this}__image {
      width: percentage((760 + 70) / $base-container);
      max-width: percentage((760 + 70) / $base-container);
      order: 0;

      @include lg {
        max-width: none;
      }
    }

    #{$this}__content {
      width: percentage(($base-container - 760 - 70)/ $base-container);

      @include container-xl {
        padding-left: 60px;
        // padding-right: 0;
      }

      @include lg {
        width: 100%;
        max-width: none;
        padding-left: 0;
      }
    }
  }
}
