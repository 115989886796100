.s-banner {
  $this: &;

  position: relative;
  color: $white;

  > .container {
    position: relative;
    z-index: 1;
  }

  &__slog {
    @include f-title;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    max-width: 500px;
    min-height: 1085px;

    padding-bottom: 95px;
    padding-top: 30px;

    margin-left: auto;

    text-transform: uppercase;

    @include xxxl {
      min-height: vw(1085);
    }

    @include lg {
      max-width: 800px;
    }

    @include md {
      min-height: ceil(1085 / $line-size-index-md) + px;
      max-width: 65%;
    }

    @include sm {
      max-width: 300px;
    }

    @include xs {
      min-height: 820px;
      max-width: none;
      text-align: center;
      padding-bottom: 80px;
    }
  }

  &__quote {
    @extend .title;

    font-size: 40px;

    text-shadow: 1px 1px 2px rgba($black, 0.9);
    margin-bottom: vw(75);
  }

  &__caption {
    margin-left: auto;

    @include xs {
      margin-right: auto;
    }
  }

  &__bg {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: $background;
    height: 100%;

    img {
      @extend %coverdiv;

      @extend %coverimage;

      z-index: 0;
    }
  }

  &--home {
    #{$this}__slog {
      min-height: 1em;
      // height: 1085px;
      height: vw(1085);

      @include md {
        height: ceil(1085 / $line-size-index-md) + px;
      }

      @include xs {
        height: 820px;
        padding-bottom: 30px;
      }
    }

    #{$this}__quote {
      @include sm {
        font-size: 30px;
      }

      @include xs {
        font-size: 26px;
      }
    }
  }

  &--left {
    #{$this}__slog {
      justify-content: flex-start;
      margin-left: percentage(270 / $base-container);
      padding-top: vw(490);
      height: vw(1390);
      max-width: none;

      @include md {
        margin-left: 0;
        height: ceil(1085 / $line-size-index-md) + px;
      }

      @include xs {
        text-align: left;
        height: 565px;

        justify-content: flex-end;
      }
    }

    #{$this}__quote {
      margin-top: 10%;

      @include md {
        margin-top: 20%;
      }

      @include sm {
        margin-top: 30%;
      }

      @include xs {
        margin-top: 0;
      }
    }

    #{$this}__bg {
      img {
        @include xs {
          object-position: 70% 50%
        }
      }
    }
  }

  &--center {
    #{$this}__slog {
      justify-content: center;

      @include xs {
        height: 565px;
      }
    }

    #{$this}__quote {
      margin-bottom: 0;
    }
  }

  &--center-center {
    #{$this}__slog {
      justify-content: center;
      align-items: center;
      margin-right: auto;

      padding-top: 30px;
      padding-bottom: 30px;

      @include xs {
        height: 565px;
      }
    }

    #{$this}__quote {
      margin-bottom: 0;
    }
  }

  &--white {
    background-color: $white;
    color: inherit;
  }

  &--transform {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: $black-light;
    height: 200vh;

    #{$this}__inner-wrap {
      @extend %flex-center;

      width: 100%;
      height: 100vh;
      max-width: 100%;
      max-height: 100%;
    }

    #{$this}__inner {
      position: relative;
      width: 100%;
      height: 100%;

      overflow: hidden;
    }

    #{$this}__bg {
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);

      width: 100vw;
      height: 100vh;
    }

    #{$this}__slog {
      position: relative;
      z-index: 1;
      max-width: none;
      min-height: 0 !important;
      height: 100%;

      padding-top: 0;
      padding-bottom: 0;
    }

    #{$this}__quote {
      @extend .title--h2;

      white-space: nowrap;
      transform: translate(100%, 0);
    }
  }

  &--flex-end {
    justify-content: flex-end;
  }
}
