.s-about {
  position: relative;

  > .container {
    position: relative;
    z-index: 1;
  }

  &__title {
    opacity: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    max-width: 1042px;
    height: vw(840);
    position: relative;

    @include xxxl {
      justify-content: flex-end;
    }

    @include lg {
      padding-left: 70px;
    }

    @include md {
      height: ceil(1042 / $line-size-index-md) + px;
      justify-content: center;
    }

    @include xs {
      height: 920px;
      padding-left: 0;
      padding-top: 150px;
      padding-bottom: 30px;
    }
  }

  &__compass {
    margin-top: -40px;
    margin-left: 57px;

    @include xs {
      margin-top: 40px;
      margin-left: 90px;
    }
  }

  &__text {
    font-size: 0.95em;
    line-height: 1.15;

    p {
      opacity: 0;

      @include container-xl {
        max-width: 93%;
      }

      @include lg {
        max-width: none;
      }
    }

    @include xl {
      .title {
        margin-bottom: 30px;

        @include lg {
          margin-bottom: 15px;
        }
      }

      .btn {
        margin-top: 0;
      }
    }
  }
}

.s-about-1 {
  padding-top: 65px;
  padding-bottom: 150px;

  @include lg {
    padding-bottom: 100px;
  }

  @include sm {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  &__title {
    opacity: 0;
  }

  &__content {
    position: relative;
  }

  &__compass {
    position: absolute;
    bottom: 30px;
    left: 30%;

    @include xxxl {
      left: 10%;
    }

    @include lg {
      position: static;
      text-align: center;
      margin-top: 57px;
    }
  }

  &__text {
    max-width: percentage((772 + 65) / $base-container);
    margin-left: auto;
    padding-right: 65px;

    @include xxxl {
      max-width: 70%;
    }

    @include lg {
      max-width: none;
      padding-right: 0;
    }

    p {
      opacity: 0;
    }
  }
}

.s-about-2 {
  background-color: $white;
  padding-top: 75px;
  padding-bottom: 105px;

  @include sm {
    padding-top: 0px;
    padding-bottom: 50px;
  }

  &__image {
    @extend %section-image;

    margin-top: -(75) + px;
    position: relative;

    width: 819px;
    height: 894px;

    @include xxxl {
      width: vw(819);
      height: vw(894);
    }

    @include lg {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      margin-bottom: 50px;
    }

    @include xs {
      width: calc(100% + #{$base-container-gap * 2 + px});
      height: auto;
      padding-top: percentage(894 / 819);

      margin-left: -$base-container-gap + px;
      margin-right: -$base-container-gap + px;
    }

    img {
      @extend %coverdiv;
      @extend %coverimage;
    }
  }
}

.s-about-3,
.s-about-4 {
  &__images {
    position: relative;

    @include lg {
      margin-bottom: 70px;
      margin-left: auto;
      margin-right: auto;
      width: vw(704) !important;
    }

    @include xs {
      margin-left: -$base-container-gap + px;
      margin-right: -$base-container-gap + px;
      width: calc(100% + #{$base-container-gap * 2 + px}) !important;
    }
  }

  &__image {
    position: relative;
    background-color: $background;

    width: 704px;
    height: 1009px;

    @include xxxl {
      width: vw(704);
      height: vw(1009);
    }

    @include container-xl {
      width: 100%;
      height: auto;
      padding-top: percentage(1009 / 704);
    }

    @include lg {
      width: vw(704);
      height: vw(1009);
      padding-top: 0;
    }

    @include xs {
      width: 100%;
      height: auto;
      padding-top: percentage(1009 / 704);
    }

    img {
      @extend %coverdiv;
      @extend %coverimage;
    }

    &--sm {
      @extend %section-image;
      position: absolute;
      top: 60%;
      left: 22%;

      width: 563px;
      height: 389px;

      @include xxxl {
        width: vw(563);
        height: vw(389);
      }

      @include container-xl {
        width: 90%;
        height: auto;
        padding-top: 60%;
        top: 50%;
        left: 30px;
      }

      @include lg {
        width: vw(563);
        height: vw(389);
        padding-top: 0;

        top: 70%;
      }

      @include xs {
        height: auto;
        width: 70%;
        padding-top: 50%;
      }
    }
  }
}

.s-about-3 {
  padding-top: 56px;
  padding-bottom: 101px;

  @include xs {
    padding-top: 0px;
    padding-bottom: 50px;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__compass {
    margin-top: 70px;
    margin-left: auto;
  }
}

.s-about-4 {
  padding-top: 67px;
  padding-bottom: 51px;
  background-color: $white;

  @include xs {
    padding-top: 0px;
  }

  &__image {
    margin-left: auto;

    &--sm {
      left: auto;
      top: 50%;
      right: 25%;

      @include container-xl {
        right: 30px;
      }

      @include lg {
        top: 70%;
      }
    }
  }
}

.s-about-5 {
  position: relative;
  margin-top: vw(-260);

  color: $white;

  padding-top: 75px;
  padding-bottom: 119px;

  @include lg {
    padding-right: 50px;
    padding-bottom: 75px;
  }

  @include sm {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  &::before {
    content: '';

    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(100% - #{vw(260)});

    background-color: $black;
  }

  &::after {
    content: '';

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: vw(261);
    background-image: linear-gradient(to top, $black, rgba($black, 0.7));
  }

  &__inner {
    position: relative;
    z-index: 1;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }

  &__subttl,
  &__text {
    width: 50%;

    @include sm {
      width: 100%;
    }
  }

  &__subttl {
    @include sm {
      margin-bottom: 20px;
    }

    .title {
      margin-bottom: 10px;
    }
  }

  &__text {
    padding-top: 1em;

    @include sm {
      padding-top: 0;
    }
  }

  &__text-wrap {
    @extend %row;
    position: relative;
  }
}
